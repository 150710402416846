.navbar {
  padding-top: 8vh;

  header {
    .open-nav-button {
      cursor: pointer;
      display: none;
      font-size: 30px;
      position: absolute;
      top: 15px;
      left: 15px;
    }
    nav {
      .mobile-menu {
        padding-bottom: 20vh;
        display: none;
        position: fixed;
        list-style-type: none;
        top: 0px;
        padding-left: 0px;
        z-index: 10;
        transform: translateX(-283px);
        min-height: 100vh;
        height: 100%;
        overflow-y: auto;
        width: 282px;
        transition: 800ms;
        background-color: #444;

        .close-nav-button {
          align-items: center;
          display: flex;
          margin-bottom: 30px;
          justify-content: right;
          i {
            top: 15px;
            position: relative;
            right: 25px;
            font-size: 30px;
            color: white;
            cursor: pointer;
          }
        }

        li {
          p {
            margin-bottom: 0px;
            padding-left: 6px;
          }
          font-weight: 500;
          padding: 9px 0px;
          font-size: 20px;
          margin: 0px;
          border-bottom: 1px solid rgba(29, 29, 29, 0.742);
          .dropbtn {
            .dropdown-content {
              display: flex;
              flex-direction: column;
              a {
                border-bottom: 1px solid rgba(29, 29, 29, 0.742);
                padding: 9px 0px;
                padding-left: 17px;
                cursor: pointer;
                color: rgb(162, 162, 162);
                font-size: 17px;
              }

              a:hover {
                color: #ed786a;
                font-weight: 600;
              }
            }
          }
          i {
            display: none;
          }
        }
        * {
          color: white;
        }
      }
      .desktop-menu {
        display: flex;
        width: 100vw;
        justify-content: space-evenly;
        .close-nav-button {
          display: none;
          cursor: pointer;
        }

        li {
          list-style: none;
          font-weight: 500;
          color: #666;
          padding: 0;
          display: flex;
          align-items: center;
          font-size: 20px;
          &:hover {
            a {
              color: #ed786a;
            }
            i {
              background-color: #ed786a;
            }
            .dropbtn {
              color: #ed786a;
              .dropdown-content {
                a {
                  color: #444;
                }
              }
            }
          }
          * {
            text-transform: uppercase;
          }
          cursor: pointer;
          .dropbtn {
            .dropdown-content {
              display: none;
              flex-direction: column;
              position: absolute;
              border: 1px solid rgba(136, 136, 136, 0.132);
              border-radius: 10px;
              border-top-left-radius: 0px;
              padding: 10px;
              width: max-content;
              background-color: #f9f9f9;
              min-width: 160px;
              box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
              z-index: 1;

              a {
                border-bottom: 0.5px solid rgba(136, 136, 136, 0.258);
                border-width: 100%;
                text-transform: none !important;
                margin: 5px 0px;
              }
              a:hover {
                color: #ed786a;
              }
            }
            &:hover {
              color: #ed786a;
              .dropdown-content {
                display: flex;
              }
            }
          }
          i {
            color: white;
            background-color: #888;
            padding: 7px;
            border-radius: 50%;
            font-size: 15px;
            margin: 0px 8px;
            -webkit-box-shadow: 1px 5px 16px -7px #000000;
            box-shadow: 1px 5px 16px -7px #000000;
            transition: hover 0.2ms;
          }
          a {
            border-bottom: none;
            transition: hover 0.2ms;
          }
        }
      }
    }
  }
}
.navbar-open {
  header {
    nav {
      .mobile-menu {
        transform: translateX(0px);
      }
    }
  }
}

@media only screen and (max-width: 991px) {
  .navbar {
    header {
      .open-nav-button {
        display: block;
      }
      nav {
        .desktop-menu {
          display: none;
        }
        .mobile-menu {
          display: block;
        }
      }
    }
  }
}
