.page-wrapper {
  background-color: white;
  min-height: 100vh;
}

.container {
  padding-top: 5% !important;
  padding-bottom: 5% !important;
  box-shadow: none !important;

  #logo {
    position: relative;
    animation: fadein 1.6s forwards;
    cursor: pointer;
  }

  p {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    justify-content: center;

    .phone-number-description {
      display: flex;
      align-items: center;

      a {
        margin-left: 15px;
        border: none;
        i {
          margin-right: 6px;
          cursor: pointer;
        }
      }
    }
  }
}

.map-container {
  height: 80vh;
  width: 100%;
}

@media screen and (max-width: 1000px) {
  .map-container {
    height: 50vh;
  }
}

@keyframes fadein {
  0% {
    top: -40px;
    opacity: 0;
  }
  100% {
    opacity: 100;
    top: 0;
  }
}

.slide-container {
  width: 100vw;
  .each_image {
    height: 80vh;
    display: flex;
    align-items: center;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    justify-content: center;

    span {
      position: relative;
      text-align: center;
      letter-spacing: 5px;
      width: 50%;
      color: white;
      word-spacing: 7px;
      line-height: 1.3;
      font-weight: 800;
      font-size: 40px;
    }
  }
}

.content {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5% 10% 0px 10%;
}

@media only screen and (max-width: 900px) {
  .content {
    padding: 0px;
  }
}

.fade {
  padding-top: 0px !important;
}

.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  opacity: 1;
  transition: opacity 1000ms;
}
.fade-exit {
  opacity: 1;
  display: none;
}
.fade-exit-active {
  opacity: 0;
  transition: opacity 1000ms;
}

.service-grid {
  display: grid;
  justify-content: center;
  padding-top: 0px !important;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 8vh;

  .card {
    section {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      img {
        width: 100%;
        height: 220px;
      }
    }
  }
}

@media only screen and (max-width: 800px) {
  .slide-container {
    .each_image {
      height: 60vh;
    }
  }
}

.spinner {
  animation: changeColor 4s infinite alternate;
  i {
    animation: 2s spinner infinite linear;
    font-size: 50px;
  }
}

@keyframes spinner {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes changeColor {
  0% {
    color: #ed786a;
  }
  50% {
    color: rgb(212, 0, 250);
  }
  100% {
    color: rgb(0, 95, 204);
  }
}

.contacts {
  width: 100vw;
  display: flex;
  padding: 15px 10%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ul {
    display: flex;
    align-items: flex-start;
    width: 100%;
    justify-content: space-between;
  }
}

@media only screen and (max-width: 960px) {
  .contacts {
    ul {
      flex-direction: column;
    }
  }
}
