.gallery-grid {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 25px;

  img {
    height: 600px;
    cursor: pointer;
    border-radius: 15px;
  }
  .p-image-preview-indicator {
    border-radius: 15px;
    padding: 0px;
  }
}
